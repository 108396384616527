<template >
    <div>
      
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new")
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div class="timeline-items__right rounded-sm w-100 p-4"><h4>Keywords</h4>
                      <table class="table__mobile table-my-code table-bordered text-align-table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>keywords.key</th>
                            <th>keywords.description</th>
                            <th>Копировать</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(keyword, index) in keywords" :key="'key-' + index">
                            <td>{{ index+1 }}</td>
                            <td class="wstatus">{{ keyword.key }}</td>
                            <td>{{ keyword.description }}</td>
                            <td class="w20">
                              <el-button
                                class="primary"
                                type="success"
                                plain
                                size="mini"
                                @click="copyLink(keyword.key)"
                               
                                ><i class="el-icon-document-copy"></i>
                                Копировать
                              </el-button>
                            </td>
                          </tr>
                        </tbody>
                      </table> 
                  </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'"> 
                      <el-form
                      ref="form"
                      status-icon
                      :model="form"
                      :rules="rules" >  
                        <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item
                                    :label="$t('message.nameGiven')"
                                    prop="name"
                                    class="label_mini"
                                    :class="mode ? 'input__day' : 'input__night'"
                                >
                                    <crm-input
                                        :size="'medium'"
                                        :inputValue="form.name"
                                        v-model="form.name"
                                        :placeholder="$t('message.nameGiven')"
                                    ></crm-input>
                                </el-form-item>
                              </el-col>
                              <el-col :span="24">
                                <el-form-item
                                :class="mode ? 'input__day' : 'input__night'"
                                    :label="$t('message.category')"
                                    prop="name"
                                    class="label_mini"
                                >
                                  <el-select
                                    class="w-100"
                                    v-model="form.category"
                                    :class="mode ? 'input__day' : 'input__night'"
                                    size="small"
                                  >
                                    <el-option label="Ариза" value="application"></el-option>
                                    <el-option label="Отпуск" value="vacation"></el-option>
                                    <el-option label="Тилхат" value="acquittance"></el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="24">
                                <div class="app-form__group mb-4">
                                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                    {{ $t("message.show_position") }}
                                  </span>
                                  <el-switch v-model="form.show_position"> </el-switch>
                                </div>
                              </el-col>
                              <el-col :span="24">
                                <select-company
                                :size="'medium'"
                                :id="form.branch_id"
                                v-model="form.branch_id"
                              ></select-company>
                              </el-col>
                              <el-col :span="24">
                                <el-form-item
                                    :label="$t('message.description')"
                                    prop="description"
                                    class="label_mini"
                                    :class="mode ? 'input__day' : 'input__night'"
                                  >
                                </el-form-item>
                                <vue-editor v-model="form.description" :editorToolbar="customToolbar" :class="mode ? 'input__day' : 'input__night'"></vue-editor>
                              </el-col>
                        </el-row>
                       
                      </el-form>
                    </div>
                  </el-col>
                </el-row>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
export default {
    mixins: [form, drawer],
    name: "NationController",
    components: { selectCompany, VueEditor },
    data() {
        return {
          keywords:[
            {
              key:'from_date',
              description:'Qachondan',
            },
            {
              key:'to_date',
              description:'Qachongacha',
            },
            {
              key:'position',
              description:'Lavozim',
            },
          ],
          customToolbar: [
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                  { align: "" },
                  { align: "center" },
                  { align: "right" },
                  { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
          ],
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "applicationType/rules",
            model: "applicationType/model",
            columns: "applicationType/columns",
            mode:"MODE",
        }),
    },
    methods: {
        ...mapActions({
            save: "applicationType/store",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        copyLink(item) {
          console.log(item)
            const value_key  =`[${item}]`;
            navigator.clipboard.writeText(value_key);
            return this.$notify({
              title: "Успешно ",
              type: "success",
              offset: 7,
              message: "Key скопирована!",
            });
          },
    },
};
</script>
<style lang="scss">
  .my_border > tbody > tr > td {
    border-right: 1px  solid #dee2e6; 
  }
  .w20{
    width: 120px;
  }
  .wstatus{
    width: 170px !important;
    font-size: 11px;
  }
</style>
